import { useNavigate } from "react-router";
import Paths from "../contants/paths";
import AuthenticationContext from "../state/authentication";
import { useContext, useEffect } from "react";

export default function UnauthenticationRequired(): void {
    const navigate = useNavigate();
    const authenticationContext = useContext(AuthenticationContext);

    useEffect(() => {
        if (
            authenticationContext.initialLoadDone &&
            authenticationContext.isAuthenticated
        ) {
            navigate(Paths.ROOT, { replace: true });
        }
    }, [authenticationContext, navigate]);
}
