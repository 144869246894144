/**
 * @generated SignedSource<<46c9401d0d9a62c6551df727b0317917>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ConfirmOTPMutation$variables = {
  code: string;
  info: string;
};
export type ConfirmOTPMutation$data = {
  readonly confirmOTP: {
    readonly accessToken: string;
  };
};
export type ConfirmOTPMutation = {
  response: ConfirmOTPMutation$data;
  variables: ConfirmOTPMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "code"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "info"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "code",
        "variableName": "code"
      },
      {
        "kind": "Variable",
        "name": "info",
        "variableName": "info"
      }
    ],
    "concreteType": "Oauth",
    "kind": "LinkedField",
    "name": "confirmOTP",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accessToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmOTPMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ConfirmOTPMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "027f419eafa8c991023975a0ebb45958",
    "id": null,
    "metadata": {},
    "name": "ConfirmOTPMutation",
    "operationKind": "mutation",
    "text": "mutation ConfirmOTPMutation(\n  $info: String!\n  $code: String!\n) {\n  confirmOTP(info: $info, code: $code) {\n    accessToken\n  }\n}\n"
  }
};
})();

(node as any).hash = "6afadfcd072b281fba431fca84ec7967";

export default node;
