import React, { ReactNode } from "react";
import styles from "./Content.module.scss";
import classnames from "classnames";

interface Props {
    children: ReactNode;
}

const Content: React.FC<Props> = ({ children }) => {
    return (
        <div className="container">
            <div className={classnames(styles.body, "col")}>{children}</div>
        </div>
    );
};

export default Content;
