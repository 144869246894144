import { CreateListFormPayload } from "./CreateListForm";
import CreateListMutation from "./CreateListMutation";
import AddSelfToListMutation from "./AddSelfToListMutation";
import { Toast } from "../../../../state/toasts";
import { ListDetailPath } from "../../../../contants/paths";

export default function (
    {
        description,
        exchangeDate,
        name,
        includeMe,
        yourName,
    }: CreateListFormPayload,
    addToast: (toast: Toast) => void,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    navigate: any,
    callback: (createdList: boolean) => void
): void {
    CreateListMutation(
        name.trim(),
        description.trim(),
        exchangeDate,
        (err, response) => {
            if (err || !response) {
                addToast({
                    message: "Error creating your list. Please try again.",
                });
                callback(false);
            } else {
                if (response.createList.lists.length > 0) {
                    if (includeMe) {
                        AddSelfToListMutation(
                            {
                                name: yourName,
                                listId: response.createList.lists[0]?.id || "",
                            },
                            (err) => {
                                if (err) {
                                    addToast({
                                        message:
                                            "Error adding self to your list",
                                    });
                                }
                                callback(true);
                                navigate(
                                    ListDetailPath(
                                        response.createList.lists[0]?.id || ""
                                    ),
                                    {
                                        replace: true,
                                    }
                                );
                            }
                        );
                    } else {
                        callback(true);
                        navigate(
                            ListDetailPath(
                                response.createList.lists[0]?.id || ""
                            ),
                            { replace: true }
                        );
                    }
                }
            }
        }
    );
}
