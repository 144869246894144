import { useCallback, useEffect, useState } from "react";
import { Toast, ToastContext } from "../state/toasts";

export default function UseToasts(): ToastContext {
    const [toasts, setToasts] = useState<{ toast: Toast; id: string }[]>([]);
    const [removedToasts, setRemoveToasts] = useState<{ [id: string]: true }>(
        {}
    );

    useEffect(() => {
        let hasChange = false;
        for (const elem of toasts) {
            if (removedToasts[elem.id]) {
                hasChange = true;
                if (elem.toast.onComplete) {
                    elem.toast.onComplete();
                }
            }
        }
        if (hasChange) {
            setToasts(toasts.filter((t) => !removedToasts[t.id]));
        }
    }, [toasts, removedToasts, setToasts]);

    const addToast = useCallback(
        async (toast: Toast) => {
            const id = (await import("uuid")).v4();
            setToasts([...toasts, { toast, id: id }]);
            setTimeout(() => {
                setRemoveToasts((t) => ({
                    ...t,
                    [id]: true,
                }));
            }, toast.duration || 3000);
        },
        [setRemoveToasts, toasts]
    );

    const undoToast = useCallback(
        (id: string) => {
            for (const elem of toasts) {
                if (elem.id === id && elem.toast.undo) {
                    elem.toast.undo();
                }
            }
            setToasts(toasts.filter((t) => t.id !== id));
        },
        [toasts, setToasts]
    );

    return {
        toasts,
        addToast,
        undoToast,
    };
}
