import { graphql } from "babel-plugin-relay/macro";
import { HollyError } from "../../../../contants/error";
import perform from "../../../../services/perform";
import {
    AddSelfToListMutation$data,
    AddSelfToListMutation$variables,
} from "./__generated__/AddSelfToListMutation.graphql";

const mutation = graphql`
    mutation AddSelfToListMutation($name: String!, $listId: ID!) {
        addSelfToList(name: $name, listId: $listId) {
            id
        }
    }
`;

export default function (
    input: AddSelfToListMutation$variables,
    callback: (err: HollyError | undefined) => void
): void {
    perform<AddSelfToListMutation$data>(mutation, input, callback);
}
