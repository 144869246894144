import React from "react";
import Paths from "./contants/paths";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Root from "./screens/Root";
import UseAuthentication from "./hooks/UseAuth";
import AuthenticationContext from "./state/authentication";
import ToastsContext from "./state/toasts";
import InitialFormContext from "./state/initialForm";
import ToastContainer from "./screens/shared/ToastContainer";
import UseToasts from "./hooks/UseToasts";
import UseInitialForm from "./hooks/UseInitialForm";
import Unauthenticated from "./screens/unauthenticated/Unauthenticated";
import LazyPage from "./screens/authenticated/shared/LazyPage";

const MembershipConfirmV2 = React.lazy(
    () => import("./screens/membershipconfirmv2/MembershipConfirmV2")
);
const PublicPopularItems = React.lazy(
    () => import("./screens/publicPopularItems/PublicPopularItems")
);
const EditList = React.lazy(() => import("./screens/edit/EditList"));
const PublicWishlist = React.lazy(
    () => import("./screens/publicWishlist/PublicWishlist")
);
const Authenticated = React.lazy(
    () => import("./screens/authenticated/Authenticated")
);
const MembershipConfirm = React.lazy(
    () => import("./screens/membershipConfirm/MembershipConfirm")
);

const App: React.FC = () => {
    const authenticationValue = UseAuthentication();
    const toastsValue = UseToasts();
    const initialFormValue = UseInitialForm();

    return (
        <>
            <meta charSet="utf-8" />
            <title>Simple Secret Santa Generator</title>
            <InitialFormContext.Provider value={initialFormValue}>
                <AuthenticationContext.Provider value={authenticationValue}>
                    <ToastsContext.Provider value={toastsValue}>
                        <Router>
                            <Routes>
                                <Route
                                    path={Paths.MEMBERSHIP_CONFIRM}
                                    element={
                                        <LazyPage>
                                            <MembershipConfirm />
                                        </LazyPage>
                                    }
                                />
                                <Route
                                    path={Paths.MEMBERSHIP_CONFIRM_V2}
                                    element={
                                        <LazyPage>
                                            <MembershipConfirmV2 />
                                        </LazyPage>
                                    }
                                />
                                <Route
                                    path={Paths._AUTHENTICATED}
                                    element={
                                        <LazyPage>
                                            <Authenticated />
                                        </LazyPage>
                                    }
                                />
                                <Route
                                    path={Paths.UNAUTHENTICATED}
                                    element={<Unauthenticated />}
                                />
                                <Route
                                    path={Paths.WISHLIST}
                                    element={
                                        <LazyPage>
                                            <PublicWishlist />
                                        </LazyPage>
                                    }
                                />
                                <Route
                                    path={Paths._EDIT_LIST}
                                    element={
                                        <LazyPage>
                                            <EditList />
                                        </LazyPage>
                                    }
                                />
                                <Route
                                    path={Paths.PUBLIC_POPULAR_ITEMS}
                                    element={
                                        <LazyPage>
                                            <PublicPopularItems />
                                        </LazyPage>
                                    }
                                />
                                <Route
                                    caseSensitive={false}
                                    path={Paths.ROOT}
                                    element={<Root />}
                                />
                            </Routes>
                        </Router>
                        <ToastContainer />
                    </ToastsContext.Provider>
                </AuthenticationContext.Provider>
            </InitialFormContext.Provider>
        </>
    );
};

export default App;
