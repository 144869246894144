/**
 * @generated SignedSource<<a55202e3dababc9ce471ad1a45507a60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginSendOTPMutation$variables = {
  info: string;
};
export type LoginSendOTPMutation$data = {
  readonly sendOTP: {
    readonly info: string;
  };
};
export type LoginSendOTPMutation = {
  response: LoginSendOTPMutation$data;
  variables: LoginSendOTPMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "info"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "info",
        "variableName": "info"
      }
    ],
    "concreteType": "OTPVerify",
    "kind": "LinkedField",
    "name": "sendOTP",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "info",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginSendOTPMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginSendOTPMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ac971768b93b10b9bbc94e7323455e72",
    "id": null,
    "metadata": {},
    "name": "LoginSendOTPMutation",
    "operationKind": "mutation",
    "text": "mutation LoginSendOTPMutation(\n  $info: String!\n) {\n  sendOTP(info: $info) {\n    info\n  }\n}\n"
  }
};
})();

(node as any).hash = "48e6529b73113f22a64fe83cb2fbb26b";

export default node;
