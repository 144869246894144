import React from "react";
import { Link } from "react-router-dom";
import Paths from "../../../../contants/paths";
import styles from "./LandingTopBar.module.scss";

const LandingTopBar: React.FC = () => {
    return (
        <div className={styles.topBarContainer}>
            <Link to={Paths.LOGIN}>Already have an account? Login</Link>
        </div>
    );
};

export default LandingTopBar;
