import React, { useCallback, useContext, useState } from "react";
import styles from "./Login.module.scss";
import LoginSendOTPMutation from "./LoginSendOTPMutation";
import { getDefaultErrorText } from "../../../../contants/error";
import { useNavigate } from "react-router";
import { ConfirmCodePath } from "../../../../contants/paths";
import HollyButton from "../../../shared/HollyButton";
import InitialFormContext from "../../../../state/initialForm";
import { Helmet } from "react-helmet";

const Login: React.FC = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const onSetEmail: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
        },
        [setEmail]
    );

    const login = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setLoading(true);
            if (loading) {
                return;
            }
            LoginSendOTPMutation(email, (err, info) => {
                setLoading(false);
                if (err) {
                    setError(getDefaultErrorText(err));
                } else if (info) {
                    navigate(ConfirmCodePath(info));
                }
            });
        },
        [loading, navigate, email]
    );

    const { payload } = useContext(InitialFormContext);

    const descriptionText = React.useMemo(() => {
        if (payload) {
            return "Log in or create an account using your email or phone number.";
        } else {
            return "Next step, enter your email or use a phone number to create an account. The account will be used to retrieve your secret santa list later.";
        }
    }, [payload]);

    return (
        <>
            <Helmet>
                <title>Login to Secret Santa</title>
            </Helmet>
            <form onSubmit={login}>
                <h4>Login</h4>
                <p>{descriptionText}</p>
                <div className={styles.inputContainer}>
                    <div className="form-group">
                        <label htmlFor="emailInput">Email address</label>
                        <input
                            onChange={onSetEmail}
                            value={email}
                            type="email"
                            className="form-control"
                            id="emailInput"
                            aria-describedby="emailHelp"
                            placeholder="youremail@domain.com"
                        />
                    </div>
                </div>
                <div className={styles.spacer} />
                <HollyButton
                    type="submit"
                    className="w-100"
                    text="Next"
                    loading={loading}
                />
                {error && <small className={"primary-text"}>{error}</small>}
            </form>
        </>
    );
};

export default Login;
