import { graphql } from "babel-plugin-relay/macro";
import { HollyError } from "../../../../contants/error";
import perform from "../../../../services/perform";
import { ConfirmOTPMutation$data } from "./__generated__/ConfirmOTPMutation.graphql";

const mutation = graphql`
    mutation ConfirmOTPMutation($info: String!, $code: String!) {
        confirmOTP(info: $info, code: $code) {
            accessToken
        }
    }
`;

export default function ConfirmOTPMutation(
    info: string,
    code: string,
    callback: (err: HollyError | undefined, token: string | undefined) => void
): void {
    perform<ConfirmOTPMutation$data>(
        mutation,
        { info, code },
        (err, output) => {
            callback(err, output?.confirmOTP.accessToken);
        }
    );
}
