import React, { useContext } from "react";
import Content from "../authenticated/shared/Content";
import ToastsState from "../../state/toasts";
import Toast from "./Toast";
import styles from "./ToastContainer.module.scss";

const ToastContainer: React.FC = () => {
    const { toasts } = useContext(ToastsState);

    if (toasts.length === 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            <Content>
                {toasts.map((toast) => (
                    <Toast id={toast.id} key={toast.id} toast={toast.toast} />
                ))}
            </Content>
        </div>
    );
};

export default ToastContainer;
