import React, { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import querystring from "querystring";
import LoginSendOTPMutation from "../login/LoginSendOTPMutation";
import { getDefaultErrorText } from "../../../../contants/error";
import ConfirmOTPMutation from "./ConfirmOTPMutation";
import AuthenticationContext from "../../../../state/authentication";
import InitialFormContext from "../../../../state/initialForm";
import useFormattedContactInfo from "../../../../hooks/UseContactInfo";
import classnames from "classnames";
import styles from "./ConfirmCode.module.scss";
import HollyButton from "../../../shared/HollyButton";
import runCreateListMutation from "../../../authenticated/screens/createList/runCreateListMutation";
import toasts, { ToastContext } from "../../../../state/toasts";
import { CreateListPath } from "../../../../contants/paths";
import { Helmet } from "react-helmet";

const ConfirmCode: React.FC = () => {
    const navigate = useNavigate();
    const { setAccessToken } = useContext(AuthenticationContext);
    const [code, setCode] = useState("");
    const location = useLocation();
    const info = useMemo(() => {
        const decoded = querystring.decode(location.search);
        const info = decoded["?info"] as string;
        return info;
    }, [location]);
    const formattedInfo = useFormattedContactInfo(info);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const onCodeChange: React.ChangeEventHandler<HTMLInputElement> =
        useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                setCode(e.target.value);
            },
            [setCode]
        );
    const { addToast } = useContext<ToastContext>(toasts);
    const { payload, setPayload } = useContext(InitialFormContext);
    const onSubmit = useCallback(() => {
        if (loading) return;
        if (code.length !== 6) {
            setError("The code is 6 characters. Please enter the code.");
            return;
        }
        setLoading(true);
        ConfirmOTPMutation(info, code, (err, token) => {
            setLoading(false);
            if (err) {
                setError(getDefaultErrorText(err));
            } else if (token) {
                setAccessToken(token);
                if (payload) {
                    runCreateListMutation(
                        payload,
                        addToast,
                        navigate,
                        (success: boolean) => {
                            if (!success) {
                                addToast({
                                    message:
                                        "We could not create your list. Please try again.",
                                });
                                navigate(CreateListPath());
                            }
                            setPayload(null);
                        }
                    );
                }
            }
        });
    }, [
        loading,
        code,
        info,
        setAccessToken,
        payload,
        addToast,
        navigate,
        setPayload,
    ]);

    const resendCode = useCallback(() => {
        setLoading(true);
        LoginSendOTPMutation(info, (err) => {
            setLoading(false);
            if (err) {
                setError(getDefaultErrorText(err));
            } else {
                setError("");
            }
        });
    }, [info, setLoading]);

    return (
        <>
            <Helmet>
                <title>Confirm Code</title>
            </Helmet>
            <div>
                <h3>Confirm Code</h3>
                <p>
                    We sent a code to {formattedInfo} enter the code to log in.
                </p>
                <form onSubmit={onSubmit}>
                    <div className="form-group">
                        <label htmlFor="codeInput">Code</label>
                        <input
                            id="codeInput"
                            className="form-control"
                            onChange={onCodeChange}
                            value={code}
                            maxLength={6}
                            type="text"
                            placeholder="######"
                        />
                    </div>
                    <HollyButton
                        className={styles.submitButton}
                        loading={loading}
                        type="submit"
                        text="Submit"
                        onClick={onSubmit}
                    />
                </form>
                <button
                    type="button"
                    className={classnames(
                        "btn btn-secondary btn-sm",
                        styles.resendButton
                    )}
                    onClick={resendCode}
                >
                    Resend Code
                </button>
                <div className="row">
                    <small className={"primary-text"}>{error}</small>
                </div>
            </div>
        </>
    );
};

export default ConfirmCode;
