import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthenticationContext from "../state/authentication";
import { AuthenticatedPath, UnauthenticatedPath } from "../contants/paths";

const Root: React.FC = () => {
    const authenticationContext = useContext(AuthenticationContext);

    if (authenticationContext.initialLoadDone) {
        if (authenticationContext.isAuthenticated) {
            return (
                <Navigate
                    replace={true}
                    to={
                        authenticationContext.onAuthenticatedPath ||
                        AuthenticatedPath()
                    }
                />
            );
        } else {
            return <Navigate replace={true} to={UnauthenticatedPath()} />;
        }
    }

    return null;
};

export default Root;
