import React from "react";
import { FadeLoader } from "react-spinners";
import styles from "./LoadingSection.module.scss";
import { Helmet } from "react-helmet";

const LoadingSection: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Loading...</title>
            </Helmet>
            <div className={styles.loadingSectionContainer}>
                <FadeLoader />
            </div>
        </>
    );
};

export default LoadingSection;
