import Environment from "../contants/environment";
import { commitMutation, Disposable, GraphQLTaggedNode } from "react-relay";
import { HollyError } from "../contants/error";

export default function <T>(
    mutation: GraphQLTaggedNode,
    variables: Record<string, unknown>,
    callback: (
        error: HollyError | undefined,
        output: T | undefined,
        errorPayload?: string
    ) => void
): Disposable {
    return commitMutation(Environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
            if (errors) {
                let mistletoeError: HollyError | undefined = undefined;
                for (const error of errors) {
                    const message: string = error.message;
                    if ((Object.values(HollyError) as any).includes(message)) {
                        mistletoeError = message as HollyError;
                    }
                    if (mistletoeError) {
                        break;
                    }
                }
                callback(mistletoeError || HollyError.UNKNOWN_ERROR, undefined);
            } else {
                callback(undefined, response as T);
            }
        },
        onError: (payload: any) => {
            const errors: any[] = payload.errors;
            if (!errors || !Array.isArray(errors)) {
                callback(HollyError.UNKNOWN_ERROR, undefined);
                return;
            }
            for (const error of errors) {
                const code = error.code;
                if ((Object.values(HollyError) as any).includes(code)) {
                    const e = code as HollyError;
                    callback(e, undefined, error.payload);
                    return;
                }
            }
            callback(HollyError.UNKNOWN_ERROR, undefined);
        },
    });
}
