import React from "react";

const LandingLoginDialog: React.FC = () => {
    return (
        <div id={"loginModal"} className={"modal fade"} aria-hidden={true}>
            <div className={"modal-dialog"}>
                <div className={"modal-content"}>
                    <div className={"modal-header"}>
                        <h5 className={"modal-title"}>
                            Create Account or Login
                        </h5>
                    </div>
                </div>
                <form>
                    <div className={"modal-body"}>
                        <p>TODO: The body of the modal</p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LandingLoginDialog;
