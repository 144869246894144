import validator from "validator";

export default function useFormattedContactInfo(raw: string): string {
    if (validator.isEmail(raw)) {
        return raw;
    } else {
        const countryCodeIndex = raw.length - 10;
        const countryCode = raw.substring(0, countryCodeIndex);
        const areaCode = raw.substring(countryCodeIndex, countryCodeIndex + 3);
        const firstThree = raw.substring(
            countryCodeIndex + 3,
            countryCodeIndex + 6
        );
        const last = raw.substring(countryCodeIndex + 6);
        return `+${countryCode} (${areaCode}) ${firstThree}-${last}`;
    }
}
