import React, { Suspense } from "react";
import LoadingSection from "../../shared/LoadingSection";

interface Props {
    children: JSX.Element;
}

const LazyPage: React.FC<Props> = ({ children }) => {
    return <Suspense fallback={<LoadingSection />}>{children}</Suspense>;
};

export default LazyPage;
