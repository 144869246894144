import { graphql } from "babel-plugin-relay/macro";
import { HollyError } from "../../../../contants/error";
import perform from "../../../../services/perform";
import { LoginSendOTPMutation$data } from "./__generated__/LoginSendOTPMutation.graphql";

const mutation = graphql`
    mutation LoginSendOTPMutation($info: String!) {
        sendOTP(info: $info) {
            info
        }
    }
`;

export default function LoginSendOTPMutation(
    info: string,
    callback: (err: HollyError | undefined, info: string | undefined) => void
): void {
    perform<LoginSendOTPMutation$data>(mutation, { info }, (err, output) => {
        callback(err, output?.sendOTP.info);
    });
}
