import { graphql } from "babel-plugin-relay/macro";
import { HollyError } from "../../../../contants/error";
import perform from "../../../../services/perform";
import { CreateListMutation$data } from "./__generated__/CreateListMutation.graphql";

const mutation = graphql`
    mutation CreateListMutation(
        $name: String!
        $description: String!
        $exchangeDate: Float!
    ) {
        createList(
            name: $name
            description: $description
            exchangeDate: $exchangeDate
        ) {
            id
            lists {
                id
            }
        }
    }
`;

export default function (
    name: string,
    description: string,
    exchangeDate: Date,
    callback: (
        err: HollyError | undefined,
        response: CreateListMutation$data | undefined
    ) => void
): void {
    perform<CreateListMutation$data>(
        mutation,
        {
            name,
            description,
            exchangeDate: exchangeDate.getTime(),
        },
        callback
    );
}
