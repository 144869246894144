/**
 * @generated SignedSource<<2c101e0764d8967bce81c2556a06e480>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateListMutation$variables = {
  description: string;
  exchangeDate: number;
  name: string;
};
export type CreateListMutation$data = {
  readonly createList: {
    readonly id: string;
    readonly lists: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type CreateListMutation = {
  response: CreateListMutation$data;
  variables: CreateListMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "exchangeDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "exchangeDate",
        "variableName": "exchangeDate"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "Account",
    "kind": "LinkedField",
    "name": "createList",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "List",
        "kind": "LinkedField",
        "name": "lists",
        "plural": true,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateListMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateListMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "ecf0401ab54d9a42ca568f1c971dd0da",
    "id": null,
    "metadata": {},
    "name": "CreateListMutation",
    "operationKind": "mutation",
    "text": "mutation CreateListMutation(\n  $name: String!\n  $description: String!\n  $exchangeDate: Float!\n) {\n  createList(name: $name, description: $description, exchangeDate: $exchangeDate) {\n    id\n    lists {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5629eeb17e89d0d217a77bf5d13c0513";

export default node;
