/**
 * @generated SignedSource<<8556d4f797de9ed907a1650f8c4a8161>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddSelfToListMutation$variables = {
  listId: string;
  name: string;
};
export type AddSelfToListMutation$data = {
  readonly addSelfToList: {
    readonly id: string;
  };
};
export type AddSelfToListMutation = {
  response: AddSelfToListMutation$data;
  variables: AddSelfToListMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "listId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "listId",
        "variableName": "listId"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "Membership",
    "kind": "LinkedField",
    "name": "addSelfToList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddSelfToListMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddSelfToListMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0e6b4f41aef3538d9eb535c90778eee3",
    "id": null,
    "metadata": {},
    "name": "AddSelfToListMutation",
    "operationKind": "mutation",
    "text": "mutation AddSelfToListMutation(\n  $name: String!\n  $listId: ID!\n) {\n  addSelfToList(name: $name, listId: $listId) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff933c73573e57afc8512ba7e1372743";

export default node;
