import React from "react";

export interface AuthenticationContext {
    setAccessToken: (accessToken: string | null) => void;
    initialLoadDone: boolean;
    isAuthenticated: boolean;
    setOnAuthenticatedPath: (path: string) => void;
    onAuthenticatedPath: string | null;
}

const INITIAL_CONTEXT = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setAccessToken: () => {},
    isAuthenticated: false,
    initialLoadDone: false,
    onAuthenticatedPath: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setOnAuthenticatedPath: () => {},
};

export default React.createContext<AuthenticationContext>(INITIAL_CONTEXT);
