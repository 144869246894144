import React, { useCallback, useContext } from "react";
import ToastsState, { Toast as ToastInterface } from "../../state/toasts";

interface Props {
    toast: ToastInterface;
    id: string;
}

const Toast: React.FC<Props> = ({ toast, id }) => {
    const { undoToast } = useContext(ToastsState);

    const onUndo = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            undoToast(id);
        },
        [id, undoToast]
    );

    return (
        <div className="alert alert-secondary">
            {toast.message}{" "}
            {toast.undo && (
                <a href="/" className="alert-link" onClick={onUndo}>
                    Undo
                </a>
            )}
        </div>
    );
};

export default Toast;
