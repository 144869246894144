import React from "react";
import { CreateListFormPayload } from "../screens/authenticated/screens/createList/CreateListForm";

export interface InitialFormContext {
    payload: CreateListFormPayload | null;
    setPayload: (payload: CreateListFormPayload | null) => void;
}

const INITIAL_CONTEXT = {
    payload: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setPayload: () => {},
};

export default React.createContext<InitialFormContext>(INITIAL_CONTEXT);
