import React from "react";

export interface Toast {
    message: string;
    undo?: () => void;
    onComplete?: () => void;
    duration?: number;
}

export interface ToastContext {
    addToast: (toast: Toast) => void;
    toasts: { id: string; toast: Toast }[];
    undoToast: (id: string) => void;
}

const INITIAL_CONTEXT: ToastContext = {
    addToast: () => ({}),
    toasts: [],
    undoToast: () => ({}),
};

export default React.createContext<ToastContext>(INITIAL_CONTEXT);
