export enum HollyError {
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
    CODE_EXPIRED = "CODE_EXPIRED",
    ACCESS_DENIED = "ACCESS_DENIED",
    CONNECTION_ERROR = "CONNECTION_ERROR",
    LIST_CANT_BE_SEND = "LIST_CANT_BE_SENT",
    UNAUTHORIXED = "UNAUTHORIZED",
    CANT_MAKE_LIST_MATCHES = "CANT_MAKE_LIST_MATCHES",
    LINK_REQUIRED = "LINK_REQUIRED",
    INVALID_ARG = "INVALID_ARG",
    PREMIUM_REQUIRED = "PREMIUM_REQUIRED",
}

export const getDefaultErrorText = (err: HollyError): string => {
    switch (err) {
        case HollyError.UNKNOWN_ERROR:
            return "An unknown error occured. Please try again later.";
        case HollyError.CODE_EXPIRED:
            return "Your code has expired. Please get another one and try again.";
        case HollyError.ACCESS_DENIED:
            return "You are do not have access to do this.";
        case HollyError.CONNECTION_ERROR:
            return "We experienced an error. Please make sure that you are connected to the internet.";
        case HollyError.UNAUTHORIXED:
            return "You cannot perform this action. Please go back and try again";
        case HollyError.LIST_CANT_BE_SEND:
            return "This list was already sent. Please refresh and try again.";
        case HollyError.CANT_MAKE_LIST_MATCHES:
            return "There are too many blocks in your list to send it. Try removing some blocks!";
        default:
            return "An unknown error occurred. PLease try again later.";
    }
};

export const getDefaultErrorTextForError = (error: Error): string => {
    const message: string = error.message;
    if ((Object.values(HollyError) as any).includes(message)) {
        return getDefaultErrorText(message as HollyError);
    } else {
        return getDefaultErrorText(HollyError.CONNECTION_ERROR);
    }
};
