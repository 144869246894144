import { Environment, Network, RecordSource, Store } from "relay-runtime";
import { BASE_URL } from "./env";
import { ACCESS_TOKEN_KEY } from "./keys";

async function fetchQuery(operation: any, variables: any) {
    const headers: any = {
        "Content-Type": "application/json",
    };
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (accessToken) {
        headers["x-mistletoe-token"] = accessToken;
    }
    const response = await fetch(BASE_URL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            query: operation.text,
            variables,
        }),
    });
    const resp = await response.json();
    if (!response.ok || resp.errors) {
        throw resp;
    }
    return resp;
}

export default new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
});
