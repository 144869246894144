import { AuthenticationContext } from "../state/authentication";
import { useCallback, useEffect, useState } from "react";
import { ACCESS_TOKEN_KEY } from "../contants/keys";

export default function UseAuthentication(): AuthenticationContext {
    const [isAuthenticated, setIsAuthenticatedInternal] = useState<
        boolean | null
    >(null);
    const [initialLoadDone, setInitialLoadDone] = useState<boolean>(false);
    const [onAuthenticatedPath, setOnAuthenticatedPath] = useState<
        string | null
    >(null);

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop as any),
        });
        // first check the access token from the header
        let accessToken = (params as any).access_token;
        if (accessToken) {
            // set the local storage one
            localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
        }
        if (!accessToken) {
            accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        }
        if (accessToken) {
            setIsAuthenticatedInternal(true);
        } else {
            setIsAuthenticatedInternal(false);
        }
        setInitialLoadDone(true);
    }, []);

    const setAccessToken = useCallback(
        async (token: string | null) => {
            if (token) {
                localStorage.setItem(ACCESS_TOKEN_KEY, token);
                setIsAuthenticatedInternal(!!token);
                setInitialLoadDone(true);
            } else {
                localStorage.removeItem(ACCESS_TOKEN_KEY);
                setIsAuthenticatedInternal(!!token);
                setInitialLoadDone(true);
            }
        },
        [setIsAuthenticatedInternal, setInitialLoadDone]
    );

    return {
        initialLoadDone,
        setAccessToken,
        isAuthenticated: !!isAuthenticated,
        setOnAuthenticatedPath,
        onAuthenticatedPath,
    };
}
