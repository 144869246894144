import React from "react";
import classnames from "classnames";
import styles from "./HollyButton.module.scss";

interface Props {
    theme?: "btn-primary" | "btn-light";
    loading?: boolean;
    type?: "button" | "submit";
    dataToggle?: string;
    dataTarget?: string;
    disabled?: boolean;
    className?: string;
    text: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const HollyButton: React.FC<Props> = ({
    theme = "btn-primary",
    loading = false,
    text,
    type = "button",
    disabled = false,
    onClick,
    className,
    dataToggle,
    dataTarget,
}) => {
    return (
        <button
            data-bs-toggle={dataToggle}
            data-bs-target={dataTarget}
            onClick={onClick}
            type={type}
            className={classnames("btn", theme, className)}
            disabled={loading || disabled}
        >
            {loading && (
                <>
                    <span
                        className={classnames(
                            "spinner-border",
                            "spinner-border-sm"
                        )}
                        role="status"
                        aria-hidden="true"
                    />
                    <span className={styles.text}>Loading...</span>
                </>
            )}
            {!loading && <span>{text}</span>}
        </button>
    );
};

export default HollyButton;
