enum Paths {
    ROOT = "/",
    MEMBERSHIP_CONFIRM = "/membership-confirm/:membershipId",
    MEMBERSHIP_CONFIRM_V2 = "/membership-confirm-v2/:membershipId",
    UNAUTHENTICATED = "/i-am-new/*",
    _AUTHENTICATED = "/home/*",
    _LISTS = "/",
    _LIST_DETAIL = "/lists/:listId",
    _LIST_DETAIL_EDIT = "/lists/:listId/edit",
    _LIST_DETAIL_PARTICIPANT = "/lists/:listId/participant/:participantId",
    _MEMBERSHIPS = "/memberships",
    _MEMBERSHIP_DETAIL = "/memberships/:membershipId",
    _WISHLISTS = "/wishlist",
    _SETTINGS = "/settings",
    _POPULAR_ITEMS = "/popular-items",
    WISHLIST = "/wishlist/:accountId",
    _EDIT_LIST = "/edit/:listId",
    _DELETED_LISTS = "/lists/deleted",
    _OLD_LISTS = "/lists/old",
    _OLD_MEMBERSHIPS = "/memberships/old",
    LOGIN = "login",
    CONFIRM_CODE = "/confirm-code/*",
    PUBLIC_POPULAR_ITEMS = "/popular-items",
    _WISHLIST_SEARCH = "/wishlist/add",
    _CREATE_LIST = "/lists/create",
    _FEEDBACK = "/feedback",
    _MEMBERSHIP_WISHLIST = "/lists/:listId/participant/:participantId/wishlist",
    _LIST_DETAIL_SEND_CONTACT = "/lists/:listId/send/contact",
    _WISHLIST_ITEM_DETAIL = "/wishlist/item/:wishlistItemId",
    _CONTACT_PREFERENCES = "/settings/contact-preferences",
    _WISHLIST_ADD_V2 = "/wishlist/add-v2",
}

export function ListDetailParticipantPath(
    listId: string,
    membershipId: string
) {
    return `/home/lists/${listId}/participant/${membershipId}`;
}

export function ListDetailEditPath(listId: string): string {
    return `/home/lists/${listId}/edit`;
}

export function FeedbackPath(listId?: string, membershipId?: string): string {
    return `/home/feedback?listId=${listId}&membershipId=${membershipId}`;
}

export function ManageContactPreferencesPath(): string {
    return "/home/settings/contact-preferences";
}

export function CreateListPath(): string {
    return "/home/lists/create";
}

export function SettingsPath(): string {
    return "/home/settings";
}

export function PopularItemsPath(): string {
    return "/home/popular-items";
}

export function WishlistSearchPathDeprecated(): string {
    return "/home/wishlist/add";
}

export function WishlistPath(): string {
    return "/home/wishlist";
}

export function MembershipDetailPath(membershipId: string): string {
    return `/home/memberships/${membershipId}`;
}

export function MembershipsPath(): string {
    return "/home/memberships";
}

export function ListsPath(): string {
    return "/home";
}

export function ListDetailPath(listId: string) {
    return `/home/lists/${listId}`;
}

export function AuthenticatedPath(): string {
    return "/home";
}

export function ConfirmCodePath(info: string): string {
    return `/i-am-new/confirm-code?info=${encodeURIComponent(info)}`;
}

export function LoginPath(): string {
    return "/i-am-new/login";
}

export function UnauthenticatedPath(): string {
    return "/i-am-new";
}

export function OldListsPath(): string {
    return "/home/lists/old";
}

export function OldMembershipsPath(): string {
    return "/home/memberships/old";
}

export function ListDetailSendContactPath(listId: string): string {
    return `/home/lists/${listId}/send/contact`;
}

export function WishlistItemDetailPath(wishlistItemId: string): string {
    return `/home/wishlist/item/${wishlistItemId}`;
}

export function DeletedListsPath(): string {
    return "/home/lists/deleted";
}

export function WishlistAddV2(): string {
    return "/home/wishlist/add-v2";
}

export function MembershipWishlistPath(
    listId: string,
    membershipId: string
): string {
    return `/home/lists/${listId}/participant/${membershipId}/wishlist`;
}

export default Paths;
