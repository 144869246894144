import React, { useContext } from "react";
import CreateListForm, {
    CreateListFormPayload,
} from "../../../authenticated/screens/createList/CreateListForm";
import LandingTopBar from "./LandingTopBar";
import LandingLoginDialog from "./LandingLoginDialog";
import { useNavigate } from "react-router";
import { LoginPath } from "../../../../contants/paths";
import InitialFormContext from "../../../../state/initialForm";
import { Helmet } from "react-helmet";

const Landing: React.FC = () => {
    const { setPayload } = useContext(InitialFormContext);

    const navigate = useNavigate();

    const callback = React.useCallback(
        (payload: CreateListFormPayload) => {
            setPayload(payload);
            navigate(LoginPath());
        },
        [navigate, setPayload]
    );

    return (
        <>
            <Helmet>
                <title>Simple Secret Santa Generator</title>
            </Helmet>
            <LandingLoginDialog />
            <LandingTopBar />
            <h1>Simple Secret Santa Generator</h1>
            <p>
                Welcome! Please fill out the form below to get started with your
                secret santa list. After you create your list, you can have
                people add themselves, create exclusions and add to your
                wishlist.
            </p>
            <hr />
            <CreateListForm
                loading={false}
                callback={callback}
                submitButtonText={"Next"}
            />
        </>
    );
};

export default Landing;
