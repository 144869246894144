import React, { useEffect } from "react";
import UnauthenticationRequired from "../../hooks/UnauthenticationRequired";
import Content from "../authenticated/shared/Content";
import { Route, Routes, useNavigate } from "react-router";
import Paths from "../../contants/paths";
import Landing from "./screens/landing/Landing";
import Login from "./screens/login/Login";
import ConfirmCode from "./screens/confirmCode/ConfrimCode";

const Unauthenticated: React.FC = () => {
    UnauthenticationRequired();

    const navigate = useNavigate();

    useEffect(() => {
        window.location.href = "https://app.secretsanta.link";
    }, [navigate]);

    return (
        <Content>
            <Routes>
                <Route path={Paths.LOGIN} element={<Login />} />
                <Route path={Paths.CONFIRM_CODE} element={<ConfirmCode />} />
                <Route path={"/"} element={<Landing />} />
            </Routes>
        </Content>
    );
};

export default Unauthenticated;
