export const BASE_URL =
    // eslint-disable-next-line no-undef
    process.env.NODE_ENV === "development"
        ? "http://localhost:8080/graphql"
        : "https://api.secretsanta.link/graphql";

export const STRIPE_KEY =
    process.env.NODE_ENV === "development"
        ? "pk_test_51Huqb2EWIfvGhXHfjQ48D5UUO3iOIoavfxMTxPh7K8VbIm98TSQlqdVk919nnnLRPqdj7ccsI85itWibu4w61gMO000O3gZlHJ"
        : "pk_live_51Huqb2EWIfvGhXHf4b58fIgU693bPQXsrhC6nf0agm6aubSlLVqAQArmEWuVA5yQwbPcdpiuCBj3JzWGw7VTDMvn00cjokf8eJ";
