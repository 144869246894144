import React from "react";
import HollyButton from "../../../shared/HollyButton";

export interface CreateListFormPayload {
    name: string;
    exchangeDate: Date;
    description: string;
    includeMe: boolean;
    yourName: string;
}

interface Props {
    submitButtonText?: string;
    loading: boolean;
    callback: (payload: CreateListFormPayload) => void;
}

const CreateListForm: React.FC<Props> = ({
    loading,
    callback,
    submitButtonText,
}) => {
    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [nameError, setNameError] = React.useState(false);
    const [descriptionError, setDescriptionError] = React.useState(false);
    const [exchangeDate, setExchangeDate] = React.useState("12/25/2022");
    const [exchangeDateError, setExchangeDateError] = React.useState(false);
    const [includeMe, setIncludeMe] = React.useState(true);
    const [yourName, setYourName] = React.useState("");
    const [yourNameError, setYourNameError] = React.useState(false);

    const onNameChangeEvent = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setName(e.target.value);
            setNameError(false);
        },
        [setNameError, setName]
    );

    const onDescriptionChangeEvent = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setDescription(e.target.value);
            setDescriptionError(false);
        },
        [setDescriptionError, setDescription]
    );

    const onExchangeDateChangeEvent = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setExchangeDate(e.target.value);
            setExchangeDateError(false);
        },
        [setExchangeDateError, setExchangeDate]
    );

    const onSetYourNameChangeEvent = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setYourName(e.target.value);
            setYourNameError(false);
        },
        [setYourName, setYourNameError]
    );

    const onSetIncludeMeChanged = React.useCallback(() => {
        setIncludeMe(!includeMe);
    }, [includeMe]);

    const onFormSubmit = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            let hasError = false;

            if (name.trim().length === 0) {
                setNameError(true);
                hasError = true;
            }

            if (description.length === 0) {
                setDescriptionError(true);
                hasError = true;
            }

            if (exchangeDate.length === 0) {
                setDescriptionError(true);
                hasError = true;
            }

            const epoch = Date.parse(exchangeDate);
            if (isNaN(epoch)) {
                setExchangeDateError(true);
                hasError = true;
            }

            if (includeMe && yourName.length === 0) {
                setYourNameError(true);
                hasError = true;
            }

            if (hasError) {
                return;
            }

            const d = new Date(epoch);

            callback({
                name: name,
                yourName: yourName,
                description: description,
                includeMe: includeMe,
                exchangeDate: d,
            });
        },
        [callback, description, exchangeDate, includeMe, name, yourName]
    );

    return (
        <div>
            <form onSubmit={onFormSubmit}>
                <div className="form-group">
                    <label htmlFor="nameInput">Name</label>
                    <input
                        value={name}
                        onChange={onNameChangeEvent}
                        type="text"
                        className="form-control"
                        id="nameInput"
                        placeholder="ex. Family Secret Santa"
                    />
                    {nameError ? (
                        <small className="form-text text-danger">
                            Your list name is invalid. Please add a name to your
                            list.
                        </small>
                    ) : (
                        <small className="form-text text-muted">
                            This is the name of the Secret Santa List that will
                            be shared with the participants.
                        </small>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="descriptionInput">Description</label>
                    <input
                        value={description}
                        onChange={onDescriptionChangeEvent}
                        type="text"
                        className="form-control"
                        id="descriptionInput"
                        placeholder="ex. Merry Christmas! Spending limit of $25"
                    />
                    {descriptionError ? (
                        <small className="form-text text-danger">
                            Invalid description. Please input a valid
                            description to create your group.
                        </small>
                    ) : (
                        <small className="form-text text-muted">
                            Add whatever you want to for the list. This is a
                            good place to mention the spending limit if you have
                            one!
                        </small>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="exchangeDateInput">Exchange Date</label>
                    <input
                        value={exchangeDate}
                        onChange={onExchangeDateChangeEvent}
                        type="text"
                        className="form-control"
                        id="exchangeDateInput"
                        placeholder="mm/dd/yyyy"
                    />
                    {exchangeDateError ? (
                        <small className="form-text text-danger">
                            Invalid Excange Date. Please input an exchange date
                            in the format like 12/25/20.
                        </small>
                    ) : (
                        <small className="form-text text-muted">
                            The date that your list will be exchanged
                        </small>
                    )}
                </div>
                <div className="form-group form-check">
                    <input
                        checked={includeMe}
                        onChange={onSetIncludeMeChanged}
                        type="checkbox"
                        className="form-check-input"
                        id="includeMeCheck"
                    />
                    <label
                        className="form-check-label"
                        htmlFor="includeMeCheck"
                    >
                        Include Me in the List
                    </label>
                </div>
                {includeMe && (
                    <div className="form-group">
                        <label htmlFor="yourNameInput">Your Name</label>
                        <input
                            value={yourName}
                            onChange={onSetYourNameChangeEvent}
                            type="text"
                            className="form-control"
                            id="yourNameInput"
                            placeholder="ex. Anthony"
                        />
                        {yourNameError ? (
                            <small className="form-text text-danger">
                                Invalid name. Please input a valid name to
                                continue.
                            </small>
                        ) : (
                            <small className="form-text text-muted">
                                This is the name that whoever you are matched
                                with will see.
                            </small>
                        )}
                    </div>
                )}
                <HollyButton
                    loading={loading}
                    type="submit"
                    text={submitButtonText ?? "Next"}
                />
            </form>
        </div>
    );
};

export default CreateListForm;
