import { InitialFormContext } from "../state/initialForm";
import { useState } from "react";
import { CreateListFormPayload } from "../screens/authenticated/screens/createList/CreateListForm";

export default function UseInitialForm(): InitialFormContext {
    const [payload, setPayload] = useState<CreateListFormPayload | null>(null);

    return {
        payload: payload,
        setPayload: setPayload,
    };
}
